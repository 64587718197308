<template>
    <el-form
        label-width="150px"
        label-position="left"
        :model="form"
        :rules="rules"
        ref="clothesForm"
        hide-required-asterisk
        class="clothes-form">
        <!-- 服饰名称 -->
        <el-form-item
            label="服饰名称">
            <div class="clothes-form-title">{{info.goods_name}}</div>
        </el-form-item>
        <!-- 三围/尺码 -->
        <el-form-item
            label="三围/尺码">
            <div>{{info.bwh || '**' + "/" + (info.size || '**')}}</div>
        </el-form-item>
        <!-- 出租联系 -->
        <el-form-item
            label="手机号">
            <div>{{info.phone}}</div>
        </el-form-item>
      <template v-if="info.order_type>1">
        <!-- 购买价格 -->
        <el-form-item
            label="购买价格">
          <div class="clothes-form-price">
            <span>¥</span>
            <span>{{info.sellPrice}}</span>
          </div>
        </el-form-item>
      </template>
      <template v-if="info.order_type!=2">
        <!-- 押金 -->
        <el-form-item
            label="押金">
            <div class="clothes-form-price">
                <span>¥</span>
                <span>{{info.goods_deposit}}</span>
            </div>
        </el-form-item>
        <!-- 租金 -->
        <el-form-item
            label="租金">
            <div class="clothes-form-price">
                <span>¥</span>
                <span>{{info.goods_price}}</span>
                <span>/天</span>
            </div>
        </el-form-item>
        <el-form-item
            label="租借总价">
            <div class="clothes-form-price">
                <span>¥</span>
                <span>{{totalPrice}}</span>
            </div>
        </el-form-item>
        <!-- 开始日期 -->
        <el-form-item
            label="开始日期"
            prop="start_date">
            <el-date-picker
                v-model="form.start_date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="handleStart"
                :picker-options="startOptions">
            </el-date-picker>
        </el-form-item>
        <!-- 结束日期 -->
        <el-form-item
            label="结束日期"
            prop="end_date">
            <el-date-picker
                v-model="form.end_date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
				        @change="handleFinish"
                :picker-options="endOptions">
            </el-date-picker>
        </el-form-item>
      </template>
        <!-- 地区 -->
        <el-form-item
            label="地区">
            <div class="clothes-form-address">{{info.address}}</div>
        </el-form-item>
        <!-- 简介 -->
        <el-form-item
            label="简介">
            <div class="clothes-form-synopsis">{{info.synopsis}}</div>
        </el-form-item>
        <!-- 提交 -->
        <el-form-item>
            <el-button
                type="warning"
                class="clothes-form-submit"
                v-if="info.order_type!=2"
                @click="handleLease('clothesForm', 1)">
                <span>立即租赁</span>
            </el-button>
            <el-button
                type="warning"
                class="clothes-form-submit2"
                v-if="info.order_type>1"
                @click="handleLease('clothesForm', 2)">
                <span>立即购买</span>
            </el-button>
        </el-form-item>

    </el-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            form: {
                start_date: "",
                end_date: "",
            },
			totalPrice:"",
            rules: {
                start_date: [
                    {required: true, message: '请选择开始时间', trigger: 'blur'}
                ],
                end_date: [
                    {required: true, message: '请选择结束时间', trigger: 'blur'}
                ],
            },
            startOptions: {
                disabledDate: (time) => {
                    let now = new Date();
                    return time.getTime() < now.setDate(now.getDate()-1);
                }
            },
            endOptions: {
                disabledDate: (time) => {
                    let now = new Date(),
                    date = new Date(this.form.start_date);
                    return time.getTime() < date || time.getTime() < Date.now();
                }
            },
        }
    },
    computed: mapState({
        info: state => state.goodsDetail.info,
        is_login: state => state.user.is_login
    }),
	// 渲染初始价格
	watch:{
		info(){
			this.totalPrice = Number(this.info.goods_deposit) + Number(this.info.goods_price)
		}
	},
	mounted(){
		this.totalPrice = Number(this.info.goods_deposit) + Number(this.info.goods_price)
	},
    methods: {
        ...mapActions([
            "handleLeaseAddOrder"
        ]),
		handleFinish(){
            var time=this.DateDiff2(this.form.end_date, this.form.start_date)
            alert(time);
		},
        handleStart(val){
			this.DateDiff(this.form.end_date, this.form.start_date)
            if(val >= this.form.end_date){
                this.form.end_date = "";
            }
        },
		//计算两个日期的差
		DateDiff(date1 , date2) {    //date1和date2是2002-12-18格式
			if(date1&&date2){
				let date1Str = date1.split("-");//将日期字符串分隔为数组,数组元素分别为年.月.日
				//根据年 . 月 . 日的值创建Date对象
				let date1Obj = new Date(date1Str[0],(date1Str[1]-1),date1Str[2]);
				let date2Str = date2.split("-");
				let date2Obj = new Date(date2Str[0],(date2Str[1]-1),date2Str[2]);
				let t1 = date1Obj.getTime();
				let t2 = date2Obj.getTime();
				let dateTime = 1000*60*60*24; //每一天的毫秒数
                let minusDays = Math.floor(((t2-t1)/dateTime));//计算出两个日期的天数差
                let hours=minusDays*24;
				let days = Math.abs(minusDays);//取绝对值
				//计算总价
				this.totalPrice = Number(this.info.goods_deposit) + Number(this.info.goods_price)* Number(days)
			}else{
				return;
			}
        },
        handleLease(form, orderType){
            if(!this.is_login){ // 未登录提示
                this.$message({
                    showClose: true,
                    message: "您还未登录！",
                    type: "warning"
                })
                return;
            }
            this.$refs[form].validate((valid) =>{
                if (valid || orderType==2) {
                    this.handleLeaseAddOrder({
                        price: this.info.goods_price,
                        goods_id: this.info.goods_id,
                        lease_begin: this.form.start_date,
                        lease_end: this.form.end_date,
                        order_type:orderType,
                    }).then((res) => {
                        if(res.type){
                            this.$router.push({
                                name: "clothesPay",
                                query: {
                                    id: res.msg
                                }
                            })
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error"
                            })
                        }
                    })
                } else {
                    return false;
                }
            })
        },
    },
}
</script>

<style scoped>
.clothes-form>>>.el-form-item{
    margin-bottom: 10px;
}
.clothes-form>>>.is-required{
    margin-bottom: 20px;
}
.clothes-form>>>.el-form-item__label{
    font-weight: 100;
    color: #909399
}
/* 服饰名称 */
.clothes-form-title{
    color: #303133;
    font-size: 18px;
    font-weight: bold;
}
/* 价格 */
.clothes-form-price{
    color: #E6A23C;
}
.clothes-form-price>span + span{
    margin-left: 5px;
    font-size: 20px;
    font-weight: bold;
}
/* 地址 */
.clothes-form-address{
    color: #909399;
}
/* 提交按钮 */
.clothes-form-submit{
    width: 200px;
    height: 60px;
}
.clothes-form-submit2{
	width: 200px;
	height: 60px;
	margin-left: 15px;
}
</style>
